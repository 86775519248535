import { jsx as i, jsxs as h } from "react/jsx-runtime";
import { FriendlyTimestamp as de } from "@vgno/core";
import { Play as ue, Pullquote as ge } from "@vgno/icons";
import { mergeStyles as _e, getReadtimeMinutes as ye, classnames as m, getHotspot as he, getSrcSet as Z } from "@vgno/utils";
import { Video as pe } from "@vgno/video";
import { V as ve, f as fe, a as ke, T as qe } from "./chunks/VideoTeaser.js";
import './assets/Teaser.css';function we(e) {
  const a = Math.ceil(e);
  if (a > 60) {
    const t = Math.floor(a / 60), r = a % 60;
    return `${t} t ${r} min lesetid`;
  }
  return `${a} min lesetid`;
}
const be = "_article_1egqm_1", Te = "_author_1egqm_5", Ne = "_avatar_1egqm_16", Ae = "_content_1egqm_25", Pe = "_small_1egqm_35", Se = "_medium_1egqm_39", xe = "_large_1egqm_43", Me = "_overlay_1egqm_44", ze = "_figure_1egqm_51", De = "_image_1egqm_55", He = "_kicker_1egqm_66", Ve = "_label_1egqm_67", $e = "_link_1egqm_75", je = "_opinion_1egqm_123", Ie = "_meta_1egqm_129", Le = "_item_1egqm_132", Re = "_name_1egqm_143", Ue = "_paywall_1egqm_149", Be = "_picture_1egqm_153", Oe = "_duration_1egqm_179", Ee = "_play_1egqm_183", Fe = "_pullquote_1egqm_198", Ge = "_timestamp_1egqm_207", Je = {
  article: be,
  author: Te,
  avatar: Ne,
  content: Ae,
  small: Pe,
  medium: Se,
  large: xe,
  overlay: Me,
  figure: ze,
  image: De,
  kicker: He,
  label: Ve,
  link: $e,
  opinion: je,
  meta: Ie,
  item: Le,
  name: Re,
  paywall: Ue,
  picture: Be,
  duration: Oe,
  play: Ee,
  pullquote: Fe,
  timestamp: Ge
}, Ke = {
  large: "(min-width: 580px) 580px, 100vw",
  medium: "(min-width: 580px) 580px, 100vw",
  small: "300px"
}, Qe = {
  column: "Meninger",
  editorial: "Leder",
  "op-ed": "Meninger"
}, We = {
  dinepenger: "Dine Penger",
  vg: "VG+"
}, Xe = ({ author: e, styles: a }) => {
  var d;
  const t = e.title, { value: r } = ((d = e.contacts) == null ? void 0 : d.find(
    ({ type: v }) => v === "title"
  )) || {
    value: "Journalist"
  };
  return /* @__PURE__ */ h("div", { className: m("label-small", "label-primary", a.author), children: [
    e.imageAsset && /* @__PURE__ */ i(
      "img",
      {
        alt: `Bilde av ${t}`,
        className: a.avatar,
        loading: "lazy",
        sizes: "48px",
        src: e.imageAsset.urls[0].url,
        srcSet: Z(e.imageAsset)
      }
    ),
    /* @__PURE__ */ i("p", { className: a.name, children: t }),
    r && /* @__PURE__ */ i("p", { children: r })
  ] });
}, rt = (e) => {
  var j, I, L, R, U, B, O, E, F, G, J, K, Q, W, X, Y;
  const {
    className: a,
    tracking: t,
    fetchPriority: r,
    headlineType: d = "h2",
    kicker: v,
    loading: A,
    newsroom: P = "vg",
    useOverlay: C = !1,
    queryParams: ee = {},
    url: te,
    showTimestamp: ae = !0,
    styles: ie = {},
    contentType: y,
    promotionContent: n,
    wordCount: S,
    changes: se,
    id: f,
    customProperties: k,
    links: le,
    components: c,
    authors: x,
    breakingPrefix: re,
    hasBreakingIndicator: ne,
    restrictions: M,
    type: q,
    description: z
  } = e, s = _e(Je, ie), u = n == null ? void 0 : n.imageAsset, b = S ? ye(S) : null, ce = Object.entries(ee), T = new URL(te ?? le.canonicalUrl), D = k.frontPageTitle || e.title.value, w = se.published, oe = !!(k != null && k.paywall), H = x ? x[0] : void 0;
  for (const [l, o] of ce)
    T.searchParams.set(l, o);
  const g = ["column", "editorial", "op-ed"].includes(q), _ = q === "feature" ? "large" : e.size ?? "medium", V = _ === "large" || g && _ === "medium" || C, $ = w !== void 0 && Ye(w), N = {
    "data-track-access-level": M,
    "data-track-access-level-name": M,
    "data-track-primary-position": (t == null ? void 0 : t.primaryPosition) ?? 0,
    "data-track-bundle-position": t == null ? void 0 : t.bundlePosition,
    "data-track-target-newsroom": P,
    "data-track-id": `teaser:${f}`,
    "data-track-element-size": _,
    "data-track-element-type": (t == null ? void 0 : t.elementType) ?? "Teaser",
    "data-track-click-intent": "View",
    "data-track-target-type": "Article",
    "data-track-image-url": (L = (I = (j = n == null ? void 0 : n.imageAsset) == null ? void 0 : j.urls) == null ? void 0 : I[0]) == null ? void 0 : L.url,
    "data-track-impression": !0,
    "data-track-layout-name": t == null ? void 0 : t.layoutName,
    "data-track-name": e.title.value
  }, p = y === "video" ? ((U = (R = c == null ? void 0 : c.find((l) => {
    var o;
    return l.type === "video" ? ((o = l.videoAsset) == null ? void 0 : o.duration) !== void 0 : !1;
  })) == null ? void 0 : R.videoAsset) == null ? void 0 : U.duration) ?? 0 : 0;
  if (y === "video") {
    const l = c == null ? void 0 : c.find(
      (me) => me.type === "video"
    );
    if (Ce(l))
      return;
    const o = Ze(l);
    if (o)
      return /* @__PURE__ */ i("track-element", { ...N, children: /* @__PURE__ */ i(
        ve,
        {
          className: a,
          contentType: y,
          duration: p,
          headlineType: d,
          isOpinion: g,
          videoPreviewURL: o,
          title: D,
          url: T.toString(),
          styles: {
            article: (B = e.styles) == null ? void 0 : B.article,
            content: (O = e.styles) == null ? void 0 : O.content,
            duration: (E = e.styles) == null ? void 0 : E.duration,
            link: (F = e.styles) == null ? void 0 : F.link
          }
        }
      ) }, f);
  }
  if (y === "podcast") {
    const l = c == null ? void 0 : c.find(
      (o) => o.type === "video"
    );
    return l && /* @__PURE__ */ i("track-element", { ...N, children: /* @__PURE__ */ i(
      pe,
      {
        assetId: parseInt((G = l.videoAsset) == null ? void 0 : G.id),
        assetType: (J = l.videoAsset) == null ? void 0 : J.assetType
      }
    ) }, f);
  }
  return /* @__PURE__ */ i("track-element", { ...N, children: /* @__PURE__ */ i(
    "article",
    {
      ...V && {
        "data-color-scheme": "dark"
      },
      className: m(
        s.article,
        s[_],
        g && s.opinion,
        (g || V) && s.overlay,
        a
      ),
      children: /* @__PURE__ */ h(
        "a",
        {
          className: m("label-primary", s.link),
          "data-use-overlay-player": y === "video",
          href: T.toString(),
          children: [
            ((Q = (K = u == null ? void 0 : u.urls) == null ? void 0 : K[0]) == null ? void 0 : Q.url) && /* @__PURE__ */ h("picture", { className: s.picture, children: [
              /* @__PURE__ */ i(
                "img",
                {
                  alt: ((W = n == null ? void 0 : n.alternateText) == null ? void 0 : W.value) || "",
                  className: s.image,
                  fetchPriority: r,
                  height: (X = u.size) == null ? void 0 : X.height,
                  loading: A,
                  decoding: A === "eager" ? "async" : void 0,
                  sizes: Ke[_],
                  src: u.urls[0].url,
                  srcSet: Z(u),
                  style: {
                    objectPosition: he(u, "initial")
                  },
                  width: (Y = u.size) == null ? void 0 : Y.width
                }
              ),
              y === "video" && p !== void 0 && /* @__PURE__ */ h("div", { className: s.play, children: [
                /* @__PURE__ */ i(ue, { "aria-hidden": !0 }),
                p > 0 ? /* @__PURE__ */ i(
                  "small",
                  {
                    "aria-label": `Video på ${ke(p)}`,
                    className: m(s.duration, "label-small"),
                    children: fe(p)
                  }
                ) : void 0
              ] })
            ] }),
            /* @__PURE__ */ h(
              "div",
              {
                className: m(
                  s.content,
                  "label-small",
                  "label-primary"
                ),
                children: [
                  g && /* @__PURE__ */ i(
                    ge,
                    {
                      "aria-label": "Meninger ikon",
                      className: s.pullquote
                    }
                  ),
                  g && (q === "column" && H ? /* @__PURE__ */ i(Xe, { author: H, styles: s }) : /* @__PURE__ */ i("p", { className: s.label, children: Qe[q] })),
                  v && /* @__PURE__ */ i(
                    "p",
                    {
                      className: m(
                        s.kicker,
                        `label-${_ === "large" ? "primary" : "secondary"}`
                      ),
                      children: v
                    }
                  ),
                  /* @__PURE__ */ i(
                    qe,
                    {
                      breakingPrefix: re,
                      hasBreakingIndicator: ne,
                      headlineType: d,
                      isOpinion: g,
                      size: _,
                      title: D
                    }
                  ),
                  z && /* @__PURE__ */ i(
                    "p",
                    {
                      className: "label-small",
                      dangerouslySetInnerHTML: { __html: z }
                    }
                  ),
                  /* @__PURE__ */ h("ul", { className: m(s.meta, "label-small"), children: [
                    oe && /* @__PURE__ */ i("li", { className: m(s.paywall, s.item), children: We[P] }),
                    w && ae && /* @__PURE__ */ i("li", { className: s.item, children: /* @__PURE__ */ i(
                      de,
                      {
                        className: s.timestamp,
                        options: {
                          day: "numeric",
                          hour: $ ? !1 : void 0,
                          minute: $ ? !1 : void 0,
                          month: "short",
                          weekday: !1
                        },
                        timestamp: w
                      }
                    ) }),
                    b && b > 0 && /* @__PURE__ */ i("li", { className: m(s.item), children: we(b) })
                  ] })
                ]
              }
            )
          ]
        }
      )
    }
  ) }, f);
};
function Ye(e) {
  return (Date.now() - new Date(e).getTime()) / 36e5 > 3;
}
function Ze(e) {
  var t;
  if (!e)
    return;
  const a = (t = e.videoAsset) == null ? void 0 : t.metadata;
  return (a == null ? void 0 : a.preview_vivi_category) ?? (a == null ? void 0 : a.preview_hq) ?? void 0;
}
function Ce(e) {
  var r, d;
  if (!e)
    return !1;
  const a = /* @__PURE__ */ new Date();
  a.setHours(0, 0, 0, 0);
  const t = /* @__PURE__ */ new Date();
  return t.setHours(23, 59, 59, 999), (d = (r = e == null ? void 0 : e.videoAsset) == null ? void 0 : r.flightTimes) != null && d.start ? e.videoAsset.flightTimes.start * 1e3 >= a.getTime() && e.videoAsset.flightTimes.start * 1e3 <= t.getTime() : !1;
}
export {
  rt as Teaser,
  Ke as imageSizes
};
