import { classnames } from "@vgno/utils";
import Styles from "./SkeletonFeed.module.css";

const SkeletonFeed = () => {
  return (
    <section className={classnames(Styles.skeletonFeed)}>
      <article>
        <div className={classnames(Styles.image)} />
        <div>
          <p />
          <p />
          <p />
        </div>
      </article>
      {[...Array(6)].map((_, i) => (
        <article className={classnames(Styles.short)} key={i}>
          <div className={classnames(Styles.image)} />
          <div className={classnames(Styles.textbox)}>
            <p />
            <p />
            <p />
          </div>
        </article>
      ))}
    </section>
  );
};

export default SkeletonFeed;
