import { jsxs as h, jsx as c } from "react/jsx-runtime";
import { createElement as N, useEffect as _, useState as k, useRef as S } from "react";
import { Play as f } from "@vgno/icons";
import { classnames as i, formatEnDash as L, mergeStyles as w } from "@vgno/utils";
import { BreakingIndicator as T } from "@vgno/core";
import '../assets/VideoTeaser.css';const v = "_title_4hv7n_1", D = "_small_4hv7n_7", P = "_medium_4hv7n_12", j = "_large_4hv7n_18", F = "_opinion_4hv7n_24", H = "_text_4hv7n_28", V = "_breakingPrefix_4hv7n_40", z = "_breakingIndicator_4hv7n_46", l = {
  title: v,
  small: D,
  medium: P,
  large: j,
  opinion: F,
  text: H,
  breakingPrefix: V,
  breakingIndicator: z
}, B = ({
  as: r,
  children: n,
  ...t
}) => N(r, t, n), O = ({
  breakingPrefix: r,
  hasBreakingIndicator: n,
  headlineType: t,
  isOpinion: s = !1,
  isVideo: o = !1,
  size: e,
  title: a
}) => /* @__PURE__ */ h(
  B,
  {
    as: t,
    className: i(
      l.title,
      s && l.opinion,
      o ? ["title-medium", "font-inter"] : [l[e], "font-austin-deck"]
    ),
    children: [
      n && /* @__PURE__ */ c(T, { className: l.breakingIndicator }),
      r && /* @__PURE__ */ c("span", { className: l.breakingPrefix, children: `${r} ` }),
      /* @__PURE__ */ c("span", { className: l.text, children: L(a) })
    ]
  }
), Q = (r) => {
  const n = Math.floor(r / 1e3), t = Math.floor(n / 3600), s = Math.floor(n % 3600 / 60), o = n % 60, e = t > 0 ? `${t}:` : "", a = `${s.toString().padStart(t > 0 ? 2 : 1, "0")}`, u = `${o.toString().padStart(2, "0")}`;
  return `${e}${a}:${u}`;
}, rt = (r) => {
  const n = Math.floor(r / 1e3), t = Math.floor(n / 3600), s = Math.floor(n % 3600 / 60), o = n % 60, e = [];
  return t > 0 && e.push(`${t} time${t !== 1 ? "r" : ""}`), s > 0 && e.push(`${s} minutt${s !== 1 ? "er" : ""}`), o > 0 && e.push(`${o} sekund${o !== 1 ? "er" : ""}`), e.join(", ");
};
function q(r, n, t) {
  _(() => {
    if (!r && (n != null && n.current)) {
      const s = () => {
        t != null && t.current && (t == null || t.current.play());
      }, o = () => {
        t != null && t.current && (t == null || t.current.pause());
      }, e = n.current;
      return e.addEventListener("mouseenter", s), e.addEventListener("mouseleave", o), () => {
        e.removeEventListener("mouseenter", s), e.removeEventListener("mouseleave", o);
      };
    }
  }, [r]);
}
function A(r, n, t, s) {
  _(() => {
    if (r && (n != null && n.current)) {
      const o = (a) => {
        a.forEach((u) => {
          u.isIntersecting && !s && (t != null && t.current) ? t.current.play() : t != null && t.current && t.current.pause();
        });
      }, e = new IntersectionObserver(o, {
        threshold: 0.5
      });
      return e.observe(n.current), () => {
        n.current && e.unobserve(n.current);
      };
    }
  }, [r, n, t]);
}
function C(r, n) {
  _(() => {
    if (r) {
      let t;
      const s = () => {
        n(!0), clearTimeout(t), t = setTimeout(() => {
          n(!1);
        }, 150);
      };
      return window.addEventListener("scroll", s), () => {
        window.removeEventListener("scroll", s), clearTimeout(t);
      };
    }
  }, [r]);
}
const G = "_article_1ugrr_1", J = "_content_1ugrr_6", K = "_video_1ugrr_19", W = "_link_1ugrr_23", X = "_duration_1ugrr_52", Y = {
  article: G,
  content: J,
  video: K,
  link: W,
  duration: X
}, et = ({
  className: r = "",
  contentType: n,
  duration: t,
  headlineType: s,
  videoPreviewURL: o,
  isOpinion: e,
  onClick: a,
  title: u,
  url: E,
  styles: $ = {}
}) => {
  const [d, x] = k(!1), p = S(null), g = S(null), [I, M] = k(!1), m = w(Y, $);
  return _(() => {
    const y = window.matchMedia("(max-width: 720px)"), b = () => x(y.matches);
    return b(), y.addEventListener("change", b), () => y.removeEventListener("change", b);
  }, []), q(d, g, p), C(d, M), A(d, g, p, I), /* @__PURE__ */ c("article", { className: i(m.article, r), ref: g, children: /* @__PURE__ */ h(
    "a",
    {
      className: i("label-primary", m.link),
      "data-use-overlay-player": n === "video",
      href: E.toString(),
      onClick: a ? () => a : () => {
      },
      children: [
        o && /* @__PURE__ */ c(
          "video",
          {
            autoPlay: !1,
            className: i(m.video),
            loop: !0,
            muted: !0,
            playsInline: !0,
            ref: p,
            children: /* @__PURE__ */ c("source", { src: o, type: "video/mp4" })
          }
        ),
        /* @__PURE__ */ h(
          "div",
          {
            className: i(m.content, "label-small", "label-primary"),
            children: [
              /* @__PURE__ */ c(
                O,
                {
                  headlineType: s,
                  isOpinion: e,
                  isVideo: !0,
                  size: "medium",
                  title: u
                }
              ),
              /* @__PURE__ */ h("small", { className: i(m.duration, "label-small"), children: [
                /* @__PURE__ */ c(f, {}),
                t ? Q(t) : null
              ] })
            ]
          }
        )
      ]
    }
  ) });
};
export {
  O as T,
  et as V,
  rt as a,
  Q as f
};
