import { useStore } from "@nanostores/react";
import { Fragment, useEffect, useRef } from "react";

import { Teaser } from "@vgno/teasers/Teaser";
import SkeletonFeed from "@vgno/teasers/Skeleton";
import { Video } from "@vgno/video";
import RelatedTags from "@vgno/widgets/RelatedTags";
import VideoCarousel from "@vgno/widgets/VideoCarousel";

import { NEWSROOM } from "../consts";
import { $teaserStore, fetchTeasers } from "../utils/fetchFront";

import type { Article } from "@vgno/article";
import type { Tag, Video as VideoType } from "@vgno/vg-types";

interface Props {
  podcastEpisode: VideoType;
  relatedTags?: Tag[] | null;
  verticalVideos?: (VideoType & { url: string })[];
}

export const Feed = ({
  podcastEpisode,
  relatedTags,
  verticalVideos,
}: Props) => {
  const sentinel = useRef<HTMLDivElement | null>(null);
  const { bundles, isFinished, isLoading } = useStore($teaserStore);

  // Intersection Observer for loading more teasers
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries.some((entry) => entry.isIntersecting)) {
          fetchTeasers();
        }
      },
      {
        root: null,
        rootMargin: "100%",
        threshold: 0.1,
      },
    );

    const currentSentinel = sentinel.current;
    if (currentSentinel) {
      observer.observe(currentSentinel);
    }

    return () => {
      if (currentSentinel) {
        observer.unobserve(currentSentinel);
      }
    };
  }, [bundles.length]);

  useEffect(() => {
    const sectionFeed = document.querySelector('section[role="feed"]');

    if (sectionFeed) {
      sectionFeed.setAttribute("aria-busy", isLoading ? "true" : "false");
    }
  }, [isLoading]);

  const widgets = {
    3: (
      <Video assetId={podcastEpisode.id} assetType={podcastEpisode.assetType} />
    ),
    5: verticalVideos && (
      <VideoCarousel
        className="widget p-m background-secondary shadow-small"
        title="Korte videoer"
        videos={verticalVideos}
        page={{
          contentPublisher: "dinepenger",
          contentType: "frontpage",
          contentId: "dinepenger",
        }}
      />
    ),
    10: relatedTags ? (
      <RelatedTags className="widget" newsroom={NEWSROOM} tags={relatedTags} />
    ) : null,
  };

  return (
    <section
      data-track-curate-context="frontpage"
      style={{ display: "contents" }}
    >
      {bundles.map((bundle, bundleIndex) => {
        if (bundle.length === 0) return undefined;

        return (
          <Fragment key={bundle[0].id}>
            {widgets[bundleIndex]}
            {bundle.map((teaser, teaserIndex) => {
              const teaserSize = getTeaserSize(
                teaser,
                bundleIndex,
                teaserIndex,
              );

              return (
                <Teaser
                  key={teaser.id}
                  {...teaser}
                  tracking={{
                    bundlePosition: bundleIndex + 1,
                    primaryPosition: teaserIndex + 1,
                  }}
                  fetchPriority={bundleIndex === 0 ? "high" : "low"}
                  loading={bundleIndex >= 1 ? "lazy" : "eager"}
                  size={teaserSize}
                />
              );
            })}
          </Fragment>
        );
      })}
      {!isFinished && (
        <div ref={sentinel}>
          <SkeletonFeed />
        </div>
      )}
    </section>
  );
};

function getTeaserSize(
  teaser: Article["teaser"],
  bundleIndex: number,
  teaserIndex: number,
) {
  return teaser.characteristics.hotness >= 60 ||
    (bundleIndex === 0 && teaserIndex === 0)
    ? "medium"
    : "small";
}
